import React from 'react';
import { NextPage } from 'next';
import { ErrorProps } from 'next/error';
import { useRouter } from 'next/router';
import Link from 'next/link';
import * as Sentry from '@sentry/nextjs';

import logoBlack from '../public/images/v2-logo.svg';
import { Image, BlockWrapper, Button } from 'components';

// TO-DO: Deprecate once PODS 2.0 is rolled out
const PODS_REDIRECTS = ['wp-admin', 'wp-login'];

const Error: NextPage<ErrorProps> = ({ statusCode }) => {
  const router = useRouter();
  const is404 = statusCode === 404;

  if (is404) {
    const pathname = router.asPath;

    if (PODS_REDIRECTS.some(redirect => pathname.includes(redirect))) {
      if (typeof window !== 'undefined') Sentry.captureMessage('404: Catch-All Admin Redirect');
      window.location.href = `https://legacy.escapod.us${pathname}`;
    } else if (pathname.includes('.pdf')) {
      if (typeof window !== 'undefined') Sentry.captureMessage('404: Unknown Document');
      // TO-DO: Create permanent catch-all for lingering 404s
      router.push(`/document-center`);
    } else {
      const isPreview = pathname.includes('preview?_id=');
      if (typeof window !== 'undefined' && !isPreview) Sentry.captureMessage('404: Not Found');
    }
  }

  return (
    <BlockWrapper className={`Error Page ${statusCode}`}>
      <div className="absolute top-0 left-0 ml-6 md:ml-12 lg:ml-36 mt-12 lg:mt-24">
        <Link href="/">
          <a>
            <Image width="200" height="35" src={logoBlack} alt="Escapod Logo Black" />
          </a>
        </Link>
      </div>
      <div className="fixed top-0 left-0 right-0 bottom-0 flex flex-col justify-center items-center text-center container">
        <h1 className="w-full text-center font-grotesk-headline-news text-2xl md:text-3xl mb-6">
          {is404 ? `You've lost your way` : `Whoops! Something's not working right.`}
        </h1>
        <h2 className="tracking-wide text-fire font-grotesk-sub-headline text-xl mb-12">
          {statusCode}
          {is404 && ': Not Found'}
        </h2>
        {is404 ? (
          <p className="max-w-prose mx-auto tracking-wide text-xl mb-12">
            {`Getting lost isn't always a bad thing, but it looks like the page you were looking for is missing. Time to turn back.`}
          </p>
        ) : (
          <p className="max-w-prose mx-auto tracking-wide text-xl mb-12">
            {`Something went wrong while fulfilling this request. Return to the homepage and try again or reach out to hello@escapod.us if this problem continues.`}
          </p>
        )}
        <Button variant="fill" href="/">
          Return Home
        </Button>
      </div>
    </BlockWrapper>
  );
};

Error.getInitialProps = ({ res, err }) => {
  const statusCode = !!res ? res.statusCode : !!err ? err.statusCode : 404;

  if (!!err) Sentry.captureException(err);

  return { ...err, statusCode } as ErrorProps;
};

export default Error;
